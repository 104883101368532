import axios from "axios";
import { parseJwt } from "./utils";
import { ErrorCodes } from "./ErrorConstants";

const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;
export function handleError(error) {
  if (error?.response?.status === 400) {
    if (error.response.data.errorMessage != null) {
        return {message:error.response.data.errorMessage.toString(), errorCode: ErrorCodes.BadRequest};
    }else {
        return {message:ErrorCodes.BadRequest, errorCode: ErrorCodes.BadRequest};
    }
}  else if (error?.response?.status === 401) {
    return Error(ErrorCodes.LoginRequired);
  } else if (error?.response?.status === 404) {
     return Error(ErrorCodes.NotFound);
  } else if (error?.response?.status === 403) {
     return Error(ErrorCodes.AccessDenied);
  } else if (error?.response?.status === 500) {
    return Error(ErrorCodes.UncaughtException);
  } else {
     return Error(ErrorCodes.UncaughtException);
    }
}

export const emailCheck = (token) => {
  if (!token) {
    throw handleError(Error(ErrorCodes.LoginRequired));
  }

  const email = parseJwt(token);
  if (!email["signInNames.emailAddress"]) {
    throw Error(ErrorCodes.LogOutRequired);
  }
};

let accessToken = null;

export const API = {
  STATIC_COST_BASE_URL :'static-costs',
  STATIC_COST_TYPE :'static-costs/types',
  USER_BASE_URL: 'users',
  INVOICE_BASE_URL: 'invoices',
  ROLES_BASE_URL: 'roles',
  COMPANIES_BASE_URL: 'companies',
  ORDERS_BASE_URL : 'orders',
  PROFILE_BASE_URL :"profile",
  BULK_ORDER_BASE_URL : 'bulk/orders',
  SETTING_BASE_URL :'settings',
  BRP_BASE_URL : "production-plans/brp",
  GRAPH_BASE_URL : "production-plans/graph",
  PO_NUMBER:'purchase-order-number',
  MAINTENANCE:'maintenance',
  getToken: () => accessToken,

  setToken: (token) => {
    accessToken = token;
  },

  get: async (url) => {
    accessToken && emailCheck(accessToken);
    try {
      const path = BASE_API_URL + url;
      const data = await axios.get(path, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return data;
    } catch (error) {
      throw handleError(error);
    }
  },
  post: async (url, body) => {
    accessToken && emailCheck(accessToken);
    try {
      const path = BASE_API_URL + url;
      const data = await axios.post(path, body, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return data;
    } catch (error) {
      throw handleError(error);
    }
  },
  put: async (url, body) => {
    accessToken && emailCheck(accessToken);
    try {
      const path = BASE_API_URL + url;
      const data = await axios.put(path, body, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return data;
    } catch (error) {
      throw handleError(error);
    }
  },
  patch: async (url, body) => {
    accessToken && emailCheck(accessToken);
    try {
      const path = BASE_API_URL + url;
      const data = await axios.patch(path, body, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return data;
    } catch (error) {
      throw handleError(error);
    }
  },
  delete: async (url) => {
    accessToken && emailCheck(accessToken);
    try {
      const path = BASE_API_URL + url;
      const data = await axios.delete(path, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      return data;
    } catch (error) {
      throw handleError(error);
    }
  },
};
