import {
  createContext,
  useContext,
  useCallback,
  useState,
  useMemo,
} from "react";
import { COMPANY, SETTINGS_PAGE, STATIC_COST_PAGE, USERS_PAGE } from "../utils/RoutesConstants"
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useConfirm from "../components/ConfirmDialogProvider/ConfirmDialogProvider";
import { Box } from "@mui/material";
export const confirmContext = createContext();
export function ConfirmContext({ children }) {
  const [isNeedToConfirm, setIsNeedToConfirm] = useState(false);
  const value = useMemo(
    () => ({
      isNeedToConfirm,
      setIsNeedToConfirm,
    }),
    [isNeedToConfirm]
  );
  return (
    <confirmContext.Provider value={value}>{children}</confirmContext.Provider>
  );
}

export const LinkWithConfirm = ({ href, children, action, handleClose,clearFlexibleDemand }) => {
  const { isNeedToConfirm } = useContext(confirmContext);
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onClick = useCallback(
    async (e) => {
      e.preventDefault();
      if (isNeedToConfirm) {
        const choice = await confirm();
        if (choice) {
          (href === STATIC_COST_PAGE || href === USERS_PAGE || href === COMPANY || href === SETTINGS_PAGE) && handleClose(false);
          navigate(href);
          action && dispatch(action());
          clearFlexibleDemand && dispatch(clearFlexibleDemand());
        }
      } else {
        (href === STATIC_COST_PAGE || href === USERS_PAGE || href === COMPANY || href === SETTINGS_PAGE) && handleClose(false);
        navigate(href);
      }
    },
    [isNeedToConfirm, confirm, navigate, href, action, dispatch, handleClose,clearFlexibleDemand]
  );
  return <Box onClick={onClick}>{children}</Box>;
};
