import React, { useCallback, memo } from "react";
import styles from "./datepicker.module.css";
import calenderIcon from "../../assets/Icons/Calendar.svg";
import { DATE } from "../ComponentsConstants";
import { Box } from "@mui/material";
import PropTypes from 'prop-types';

const Datepicker = ({
  name = "",
  value = "",
  inputProps,
  errorMsg = "",
  inputRef,
  extraWrapperClass=""
}) => {
  const dateError = errorMsg ? styles.datePicker__withError : "";
  const placeHolderError = errorMsg ? styles.datePicker__placeHolder_withError : "";

  const onDatePickerFocus = useCallback(() => {
    if (inputRef.current && inputRef.current.type !== DATE) {
      inputRef.current.type = DATE;
    }

    if (inputRef.current && inputRef.current.type === DATE) {
      inputRef?.current?.showPicker();
    }
  }, [inputRef]);

  const mergedInputProps = {
    max: "2999-12-31",
    ...inputProps,
  };

  return (
      <div tabIndex="0" className={styles.datePicker}>
        <div
          className={`${ inputProps?.disabled ? styles.datePicker__Column_Disabled :styles.datePicker__Column} ${extraWrapperClass} ${dateError}`}
        >
          <div className={styles.datePicker__textAndInput}>
            {value && (
              <small className={`${styles.datePicker__placeHolder} ${placeHolderError}`}>
                {inputProps?.placeholder ?? ""}
              </small>
            )}
            <input
              className={`${styles.datePicker__DateInput} ${placeHolderError}`}
              data-testid="dateIp"
              onFocus={onDatePickerFocus}
              {...mergedInputProps}
              {...(value && {type: DATE})}
            />
          </div>
          <Box onClick={() => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }}>
          <img
            src={calenderIcon}
            alt="calendar"
            className={styles.datePicker__DateCalendarIcon}
          />
          </Box>
        </div>
        {errorMsg && <div className={styles[`${name}Error`]}>{errorMsg}</div>}
      </div>
  );
};
Datepicker.propTypes = {
  name:PropTypes.string,
  value:PropTypes.string,
  inputProps:PropTypes.any,
  errorMsg:PropTypes.string,
  inputRef:PropTypes.any,
  extraWrapperClass:PropTypes.string,
};
export default memo(Datepicker);
