import React from "react";
import ReactDOM from "react-dom/client";
import LoginLanding from "./LoginLanding.jsx";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { MsalProvider } from "@azure/msal-react";
import { HashRouter } from "react-router-dom";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { PublicClientApplication,EventType } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import theme from './common/theme/theme'
import "./assets/css/index.css";
import './i18n';

let msalInstance = new PublicClientApplication(msalConfig);

const eventCallback = (event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account);
  }
};

msalInstance.addEventCallback(eventCallback);

msalInstance.handleRedirectPromise();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <HashRouter>
        <ThemeProvider theme={theme}>
          <LoginLanding  />
        </ThemeProvider>
      </HashRouter>
    </MsalProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
