export const MAX_DEMAND_PER_HOUR = 350;
export const MIN_DEMAND_PER_HOUR = 20;
export const WARNING_DEMAND = 166;
export const TOTAL_AMOUNT_LIMIT = 10;
export const PER_HOUR_VALUE = 24;

export const START_DATE = "startDate";
export const START_HOUR = "startHour";
export const END_DATE = "endDate";
export const END_HOUR = "endHour";
export const FLOW = "Flow";
export const CAPACITY = "Est. Capacity";
export const HINT_MESSAGE = "Capacity = 2000kg, Min Limit = 150kg";
export const TOTAL_AMOUNT = "totalAmount";
export const UNIT = "unit";
export const PURCHASE_ORDER_NUMBER = "purchaseOrderNumber";
export const COMPANY_ID = "companyId";
export const ID = "id";
export const ID_PLACEHOLDER = "ID";
export const START_DATE_PLACEHOLDER = "Start Date";
export const START_HOUR_PLACEHOLDER = "Start Time";
export const END_DATE_PLACEHOLDER = "End Date";
export const END_HOUR_PLACEHOLDER = "End Time";
export const UNIT_PLACEHOLDER = "Unit";
export const COMPANY_ID_PLACEHOLDER = "Company";

export const IS_FLEXIBLE_DEMAND = "isFlexibleDemand";
export const HEADER_EDIT_FLEXIBLE_DEMAND = "Edit Flexible Demand";
export const HEADER_ADD_FLEXIBLE_DEMAND = "Add Flexible Demand";

export const ORDER_HEADER = "Order Hydrogen";
export const DELETE_ORDER = "Delete Order";
export const ORDER_HISTORY_HEADING = "Order History";
export const DELETE_ORDER_CONFIR_TEXT =
  "Are you sure you want to delete your order?";
export const SAMEDATE_ERROR = "Start Time can't be greater than End Time";
export const STARTDATE_GREATER_THAN_END_DATE =
  "Start date can't be greater than end date.";
export const REQUESTED_HYDROGEN_AMOUNT_IS_LESS =
  "The flow must be more than 20kg/h.";
export const REQUESTED_HYDROGEN_AMOUNT_IS_GREATER =
  " You can request only up to (350 kg/h).";
export const START_END_DATE_AND_TIME_SAME = " Please change date or time.";
export const FLEXIBLE_DEMAND_MINIMUM_LIMIT = 150;
export const FLEXIBLE_DEMAND_MAXIMUM_LIMIT = 2000;
export const FLEXIBLE_DEMAND_MINIMUM_LIMIT_ERROR =
  "Your request should be greater than (150 kg).";
export const FLEXIBLE_DEMAND_MAXIMUM_LIMIT_ERROR =
  "Your request should be less than (2000 kg).";
export const START_DATE_IN_THE_PAST = "You can not select a date in the past";
export const MISSING_FIELD_ERROR = "Value missing or not valid.";
export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
export const DEMAND_UNIT_OPTIONS = [
  {
    label: "kg/h",
    value: "kg/h"
  },
  {
    label: "kg/d",
    value: "kg/d"
  }
];

export const ROLES = {
  CUSTOMER: "CUSTOMER",
  SUPER_USER: "SUPER_USER",
  EVERFUEL_FINANCE:"EVERFUEL_FINANCE",
};

export const DEMAND_UNIT_OPTIONS_VALUE = {
  UNIT_PER_DAY: "kg/d",
  UNIT_PER_HOUR: "kg/h"
};

export const EDIT_WARNING_MODAL =
  "You may make changes to your order up to 48 hours before production. If you need to make a last-minute change, please contact us directly at:";
export const MAX_PRODUCTION_REF_LINE = "Max Production";
export const CURRENT_HOUR_REF_LINE = "Current Hour";
export const ORDER_LIMIT_ERROR_INDUSTRY_OFFTAKE =
  "The order should be a minimum of 2 hours and a maximum of 30 days.";
export const ORDER_LIMIT_ERROR_TRAILER_BAY =
  "The order should be a minimum of 5 hours and a maximum of 7 days.";
export const TIMEZONE_CONSTANT =
  "Please note that you are ordering from another time zone than the factory time zone (CET/CEST). Therefore, your order time might deviate from the local time shown on your computer.";
export const EDIT_PO_MESSAGE = "As this order is locked all changes except change to Purchase Order Number are not possible, please contact Everfuel factory personnel to inform them about changes to the currently planned offtake."
  export const ORDER_SUBHEADER = "Enter the following data to order hydrogen.";
export const INFO_MESSAGE_FLEXIBLE_DEMAND =
  "Add a flexible demand for trailers and EverFillers to schedule a filling. The duration can differ based on the production capacity, storage and trailer bay usage.";
export const ORDER_TYPE = {
  SEELCT_TYPE_HEADER: "Select Demand Type",
  SEELCT_TYPE_SUBHEADER: "Select a demand type to proceed.",
  INDUSTRIAL: "Industrial",
  FLEXIBLE: "Flexible",
  INDUSTRIAL_QUOTE:
    " Add a constant demand in large quantity for industrial customers.",
  FLEXIBLE_QUOTE:
    "Add demand in smaller quantity for trailers, Everfillers and liquid tanks."
};
export const DEFAULT_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";

export const FLEXIBLE = {
  SUMMARY_HEADER_P1:
    "Below is the optimized filling schedule. Add the following demand to the production plan.",
  SUMMARY_HEADER_P2:
    "Make sure to communicate with responsible logistic services to arrive at the EverFuel Fueling Station by the indicated start time.",
  AVAILABLE: "Available from",
  ARRIVAL: "Earliest Arrival",
  END_TIME_PLACEHOLDER: "Est. End Time",
  BTN_UPDATE : "Update",
  BTN_ADD : "Add",
  CONFIRM_MODAL: {
    BTN_BACK_TO_MENU : "Back to menu",
    HEADER: "Delete Demand",
    CANCEL_BTN: "Cancel",
    OK_BTN: "Delete",
    MESSAGE_FLEXIBLE_SUMMARY : "Are you sure you want to stop ordering and go back to menu screen?",
    MESSAGE: "Are you sure you want to delete Flexible Demand?"
  }
};
export const ORDER_DETAILS ={
  HEADER_NAME : "Order Details",
  SUB_HEADER_NAME :"Please check the details again.",
  TIME : "Time",
  END_TIME : "End Time",
  TOTAL_MASS : "Total",
  PER_DAY: "Per day",
  PO_NUMBER : "Purchase Order Number",
  BTN_BACK :"Back",
  BTN_UPDATE : "Update",
  BTN_ADD : "Order",
  BTN_CANCEL :"Cancel",
  BTN_BACK_TO_MENU : "Back to menu",
  MESSAGE_ORDER_DETAILS : "Are you sure you want to stop ordering and go back to menu screen?",


}