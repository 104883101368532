import { dateStringToUTCObject } from "../../utils/date-utils";
import { getUserPermissions } from "../../utils/utils";
import {
  CLEAR_ORDER_DETAILS,
  ORDER_DETAILS,
  GET_USER_DETAILS,
  SET_NETWORK_ERROR_MESSAGE,
  SET_FLEXIBLE_DEMAND,
  CLEAR_FLEXIBLE_DEMAND,
  SET_FLEXIBLE_DEMAND_ERRORS,
  GET_EDIT_ORDER_DETAILS,
  GET_FLEXIBLE_DEMAND_EDIT_ORDER_DETAILS,
  CLEAR_EDIT_FLEXIBLE_DEMAND,
  REMOVE_FIRST_ITEM_FLEXIBLE_EDIT_DEMAND,
  IS_INPUT_EMPTY,
  CLEAR_EMPTY_INPUT_ERRORS,
  SET_SETTING_DETAILS,
  GET_STATIC_COST_DETAILS,
  GET_USER_LIST_DETAILS,
  CLEAR_STATIC_COST_DETAILS,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_REQUEST,
  FETCH_DATA_ERROR,
  DELETE_ORDER_FLEXIBLE_DEMAND,
  UPDATE_ORDER_FLEXIBLE_DEMAND,
  SET_FLEXIBLEDEMAND_FLAG,
  CURRENT_INDEX,
  FETCH_ORDER_DETAILS_SUCCESS,
  CLEAR_USER_LIST_DETAILS,
  CLEAR_ROW_ORDER_DETAILS,
  CLEAR_INVOICE_DETAILS,
  GET_COMPANIES_LIST_DETAILS,
  CLEAR_COMPANY_LIST_DETAILS,
  MAINTENANCE_DETAILS,
  CLEAR_MAINTENANCE_DETAILS
} from "../constants";

export const initialState = {
  orderDetails: {
    startDate: "",
    startHour: "",
    endDate: "",
    endHour: "",
    totalAmount: "",
    heading: "",
    warningDemand: false,
    productionPerHour: 0,
    unit: ""
  },
  settings: {
    data: []
  },
  staticCostDetails: {
    startDateTime: "",
    endDateTime: "",
    amount: "",
    type: "",
    costId: ""
  },
  userListDetails: {
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    roleIds: [],
    companyIds: [],
    userId: ""
  },
  maintenanceDetails:{
    start: null,
    end: null,
    availability: '',
    note: '',
    maintenanceId:''
  },
  companiesListDetails: {
    name: "",
    contactName: "",
    contactEmail: "",
    contactPhoneNumber: "",
    customerNumber: "",
    allowedOrderTypes: [],
    companyId: ""
  },
  selectedUserDetails: {},
  userDetails: {
    firstName: "",
    lastName: "",
    email: "",
    companyId: "",
    backupContact: "",
    phoneNumber: "",
    backupEmployeeEmail: "",
    roles: [],
    permissions: [],
    companies: []
  },
  networkErrorMessage: "",
  flexibleDemand: [],
  flexibleDemandErrors: {
    isSameDate: false,
    isStartDateGreater: false,
    isSameDateAndTime: false,
    isAmtLess: false,
    isAmtGreater: false
  },
  inputEmptyErrors: {
    isIdEmpty: false,
    isCapacityEmpty: false,
    isStartDateEmpty: false,
    isEndDateEmpty: false,
    isStartHourEmpty: false,
    isEndHourEmpty: false
  },
  editOrderId: "",
  staticCost: [],
  user: [],
  invoices: [],
  invoiceDetails: {},
  types: [],
  roles: [],
  companies: [],
  editFlexibleDemand: false,
  currentIndex: -1,
  rowOrderDetails: {
    orderSummary: [],
    supplyLines: []
  }
};

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        [action.key]: action.item
      };

    case FETCH_ORDER_DETAILS_SUCCESS:
      const sortedArray = action.item?.lines.sort((a, b) => {
        return (
          dateStringToUTCObject(a.startDateTime) -
          dateStringToUTCObject(b.startDateTime)
        );
      });
      return {
        ...state,
        loading: false,
        rowOrderDetails: {
          orderSummary: [
            {
              index: action.index,
              orderNumber: action.item?.orderNumber,
              createdAt: action.item?.createdAt,
              createdBy: action.item?.createdBy,
              demandType: action.item?.demandType,
              endOffTakeTime: action.item?.endOffTakeTime,
              hydrogenDemand: action.item?.hydrogenDemand,
              id: action.item?.id,
              lines: action.item?.lines,
              company: action.item?.company,
              maxPrice: action.item?.maxPrice,
              purchaseOrderNumber: action.item?.purchaseOrderNumber,
              startOffTakeTime: action.item?.startOffTakeTime,
              status: action.item?.status,
              totalMassDelivered: action.item?.totalMassDelivered,
              totalP1HydrogenMass: action.item?.totalP1HydrogenMass,
              totalP1HydrogenPrice: action.item?.totalP1HydrogenPrice,
              totalP2HydrogenMass: action.item?.totalP2HydrogenMass,
              totalP2HydrogenPrice: action.item?.totalP2HydrogenPrice,
              trailerId: action.item?.trailerId,
              updatedBy: action.item?.updatedBy
            }
          ],
          supplyLines: sortedArray.map((itm, index) => {
            return { ...itm, index: index + 1 };
          })
        }
      };
    case FETCH_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        item: []
      };
    case ORDER_DETAILS: {
      return {
        ...state,
        orderDetails: {
          ...action.payload
        }
      };
    }
    case CLEAR_ORDER_DETAILS: {
      return {
        ...state,
        orderDetails: {
          startDate: "",
          startHour: "",
          endDate: "",
          endHour: "",
          totalAmount: "",
          heading: "",
          warningDemand: false,
          unit: ""
        },
        networkErrorMessage: "",
        editOrderId: ""
      };
    }
    case CLEAR_ROW_ORDER_DETAILS: {
      return {
        ...state,
        rowOrderDetails: {
          orderSummary: [],
          supplyLines: []
        },
        networkErrorMessage: "",
        editOrderId: ""
      };
    }
    case CLEAR_INVOICE_DETAILS: {
      return {
        ...state,
        invoiceDetails: {},
        networkErrorMessage: "",
        editOrderId: ""
      };
    }
    case GET_USER_DETAILS: {
      const permissions = getUserPermissions(action.payload.roles);
      return {
        ...state,
        userDetails: {
          firstName: action.payload.firstName || "",
          lastName: action.payload.lastName || "",
          email: action.payload.emailAddress || "",
          companyId: action.payload.companies[0]?.id || "",
          backupContact: action.payload.companies[0]?.contactName || "",
          phoneNumber: action.payload.phoneNumber || "",
          roles: action.payload.roles || [],
          permissions: permissions || [],
          companies: action.payload.companies || [],
          backupEmployeeEmail: action.payload.companies[0]?.contactEmail || "",
          company: action.payload.companies[0]?.name || ""
        }
      };
    }
    case SET_SETTING_DETAILS: {
      return {
        ...state,
        settings: {
          data: action.payload
        }
      };
    }
    case SET_NETWORK_ERROR_MESSAGE: {
      return {
        ...state,
        networkErrorMessage: action.payload
      };
    }
    case SET_FLEXIBLE_DEMAND: {
      return {
        ...state,
        flexibleDemand: [...state.flexibleDemand, action.payload]
      };
    }
    case SET_FLEXIBLEDEMAND_FLAG: {
      return {
        ...state,
        editFlexibleDemand: action.payload
      };
    }
    case CURRENT_INDEX: {
      return {
        ...state,
        currentIndex: action.payload
      };
    }
    case DELETE_ORDER_FLEXIBLE_DEMAND: {
      return {
        ...state,
        flexibleDemand: [
          ...state.flexibleDemand.filter((itm, i) => i !== state.currentIndex)
        ]
      };
    }
    case UPDATE_ORDER_FLEXIBLE_DEMAND: {
      const updatedFlexibleDemand = [
        ...state.flexibleDemand.slice(0, state.currentIndex),
        action.payload,
        ...state.flexibleDemand.slice(state.currentIndex + 1)
      ];

      return {
        ...state,
        flexibleDemand: updatedFlexibleDemand
      };
    }
    case CLEAR_FLEXIBLE_DEMAND: {
      return {
        ...state,
        flexibleDemand: [],
        networkErrorMessage: ""
      };
    }
    case SET_FLEXIBLE_DEMAND_ERRORS: {
      return {
        ...state,
        flexibleDemandErrors: {
          ...state.flexibleDemandErrors,
          ...action.payload
        }
      };
    }
    case GET_EDIT_ORDER_DETAILS: {
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          ...action.payload
        },
        editOrderId: action.payload.orderId
      };
    }
    case GET_STATIC_COST_DETAILS: {
      return {
        ...state,
        staticCostDetails: {
          ...state.staticCostDetails,
          ...action.payload
        },
        editOrderId: ""
      };
    }
    case GET_USER_LIST_DETAILS: {
      return {
        ...state,
        userListDetails: {
          ...state.userListDetails,
          ...action.payload
        },
        editOrderId: ""
      };
    }
    case MAINTENANCE_DETAILS: {
      return {
        ...state,
        maintenanceDetails: {
          ...state.maintenanceDetails,
          ...action.payload,
          maintenanceId: action.payload.id
        },
      };
    }
    case GET_COMPANIES_LIST_DETAILS: {
      return {
        ...state,
        companiesListDetails: {
          ...state.companiesListDetails,
          ...action.payload
        }
      };
    }
    case CLEAR_USER_LIST_DETAILS: {
      return {
        ...state,
        userListDetails: {
          firstName: "",
          lastName: "",
          emailAddress: "",
          phoneNumber: "",
          companyIds: "",
          roleIds: "",
          userId: ""
        }
      };
    }

    case CLEAR_MAINTENANCE_DETAILS: {
      return {
        ...state,
        maintenanceDetails: {
          start: "",
          end: "",
          availability: "",
          note: "",
          maintenanceId:""
        }
      };
    }

    case CLEAR_COMPANY_LIST_DETAILS: {
      return {
        ...state,
        companiesListDetails: {
          name: "",
          contactName: "",
          contactEmail: "",
          contactPhoneNumber: "",
          customerNumber: "",
          allowedOrderTypes: [],
          companyId: ""
        }
      };
    }
    case CLEAR_STATIC_COST_DETAILS: {
      return {
        ...state,
        staticCostDetails: {
          startDateTime: "",
          endDateTime: "",
          amount: "",
          type: "",
          costId: ""
        },
        networkErrorMessage: ""
      };
    }
    case GET_FLEXIBLE_DEMAND_EDIT_ORDER_DETAILS: {
      return {
        ...state,
        orderDetails: {
          ...state.orderDetails,
          ...action.payload,
          id: action.payload.id
        },
        editOrderId: action.payload.orderId
      };
    }
    case REMOVE_FIRST_ITEM_FLEXIBLE_EDIT_DEMAND: {
      return {
        ...state,
        flexibleDemand: [state.flexibleDemand.pop()]
      };
    }
    case CLEAR_EDIT_FLEXIBLE_DEMAND: {
      return {
        ...state,
        orderDetails: {
          startDate: "",
          startHour: "",
          endDate: "",
          endHour: "",
          totalAmount: ""
        },
        networkErrorMessage: "",
        editOrderId: ""
      };
    }
    case IS_INPUT_EMPTY: {
      return {
        ...state,
        inputEmptyErrors: {
          ...state.inputEmptyErrors,
          ...action.payload
        }
      };
    }
    case CLEAR_EMPTY_INPUT_ERRORS: {
      return {
        ...state,
        inputEmptyErrors: {
          isIdEmpty: false,
          isCapacityEmpty: false,
          isStartDateEmpty: false,
          isEndDateEmpty: false,
          isStartHourEmpty: false,
          isEndHourEmpty: false
        }
      };
    }
    default:
      return state;
  }
};
