export const COLUMN_NAME = {
    ROW: "Row",
    START_TIME: "Start Time",
    END_TIME: "End Time",
    TOTAL_DEMAND: "Total Demand",
    INDUSTRIAL: "Industrial",
    TRAILER: "Trailer Bay",
    STORAGE: "H2 in Storage",
    PPLANNED: "Planned Production (kg)",
    TOTAL_ELECTRICITY_COST: "Total Electricity Cost (per MWh)",
    ELECTRICITY_COST: "Electricity Price (per MWh)",
    FACTORY_AVAILABILITY: 'Factory Availability',
    STATUS: "Status"
}

export const id = "id";
export const startDate = "startDate";
export const endDate = "endDate";
export const totalDemand = "totalDemand";
export const industryDemand = "industryDemand";
export const flexibleDemand = "flexibleDemand";
export const storagelevel = "storagelevel";
export const productionOfHydrogen = "productionOfHydrogen";
export const totalEnergyCost = "totalEnergyCost";

export const energyPricePerHour = "energyPricePerHour";
export const factoryAvailability = "factoryAvailability";
export const status = "status";
export const PRODUCTION_PLAN_HEADER = "Production Plan";
export const BRP_GRAPH = {
    DATE: "Date:",
    ENERGY_USED: "Energy Used:",
    ENERGY_PLANED: "Energy planed:"
}
export const COLOR_INDICATOR = {
    GREEN: 'success',
    YELLOW: 'warning',
    RED: 'error',
    PRIMARY: 'primary'
}