import { Route, Routes, useNavigate } from "react-router-dom";
import { Suspense, lazy, useEffect } from "react";
import { useSelector } from "react-redux";
import ErrorPage from "./components/generic-error-page/ErrorPage";
import ErrorPageNotFound from "./components/generic-error-page/ErrorPageNotFound";
import { AuthorizedPage } from "./pages/AuthorizedPage/AuthorizedPage";
import Loading from "./components/loading/Loading";
import MainLayout from "./pages/MainLayout/MainLayout";
import UserAccess from "./pages/AuthorizedPage/UserAccess";
import { getUserCompanies } from "./utils/utils";
import {
  CREATE_DEMAND,
  VIEW_PRODUCTION_PLANS,
  VIEW_SYSTEM_PROPERTY,
  UPDATE_SYSTEM_PROPERTY,
  VIEW_DEMANDS,
  ADD_STATIC_COSTS,
  VIEW_ALL_INVOICES,
  VIEW_USER,
  ONBOARD_COMPANY,
  DELETE_COMPANY,
  UPDATE_COMPANY_DETAILS
} from "./store/constants";
import {
  ORDER_PAGE,
  ORDER_TYPE,
  ERROR_PAGE,
  PROFILE_PAGE,
  CONFIRMATION_PAGE,
  USERS_PAGE,
  STATIC_COST_PAGE,
  HOME_ROUTE,
  SETTINGS_PAGE,
  PRODUCTION_PLAN_PAGE,
  INVOICE_PAGE,
  COMPANY
} from "./utils/RoutesConstants";
import { ErrorMessage } from "./utils/ErrorConstants";
import FlexibleDemand from "./pages/flexible-demand/FlexibleDemand";
import FlexibleDemandSummary from "./pages/flexible-demand/flexible-demand-summary/FlexibleDemandSummary";

const OrderDetails = lazy(() =>
  import(
    /* webpackChunkName: "order_details" */ "./pages/order-details/OrderDetails"
  )
);

const Confirmation = lazy(() =>
  import(
    /* webpackChunkName: "confirmation" */ "./components/confirmation/Confirmation"
  )
);
const Home = lazy(() =>
  import(/* webpackChunkName: "home" */ "./pages/Home/Home")
);
const OrderPage = lazy(() =>
  import(/* webpackChunkName: "order" */ "./pages/Order/Order")
);
const OrderTypePage = lazy(() =>
  import(/* webpackChunkName: "order_type" */ "./pages/OrderType/OrderType")
);
const OrderHistory = lazy(() =>
  import(
    /* webpackChunkName: "order_history" */ "./pages/OrderHistory/OrderHistory"
  )
);
const StaticCost = lazy(() =>
  import(/* webpackChunkName: "static_cost" */ "./pages/StaticCosts/StaticCost")
);
const UserList = lazy(() =>
  import(/* webpackChunkName: "user_list" */ "./pages/User/UserList")
);

const CompaniesList = lazy(() =>
  import(/* webpackChunkName: "companies" */ "./pages/Companies/CompaniesList")
);

const InvoiceList = lazy(() =>
  import(/* webpackChunkName: "invoice_list" */ "./pages/Invoices/InvoiceList")
);

const ProductionPlan = lazy(() =>
  import(
    /* webpackChunkName: "product_plan" */ "./pages/ProductionPlan/ProductionPlan"
  )
);
const Settings = lazy(() =>
  import(/* webpackChunkName: "settings" */ "./pages/Settings/Setting")
);

const Profile = lazy(() =>
  import(/* webpackChunkName: "profile" */ "./pages/Profile/Profile")
);

const App = () => {
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.orderReducer.userDetails);
  const allowedOrderTypes = getUserCompanies(userDetails?.companies);

  useEffect(() => {
    const url = window.location.href;

    if (url.includes("state")) {
      navigate(HOME_ROUTE);
    }
  }, [navigate]);

  return (
      <Routes>
        <Route
          element={
            <AuthorizedPage>
              <MainLayout />
            </AuthorizedPage>
          }
        >
          <Route
            path="*"
            element={
              <ErrorPageNotFound message={ErrorMessage.PAGE_NOT_FOUND} />
            }
          />
          <Route
            path={HOME_ROUTE}
            element={
              <Suspense fallback={<Loading />}>
                <Home />
              </Suspense>
            }
          />
          {[
            ORDER_PAGE.ORDER,
            ORDER_PAGE.ORDER_HISTORY_INDUSTRY,
            ORDER_PAGE.ORDER_TYPE_INDUSTRY
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <UserAccess
                  userPermission={
                    allowedOrderTypes?.includes(ORDER_TYPE.INDUSTRY_OFFTAKE) &&
                    CREATE_DEMAND
                  }
                >
                  <Suspense fallback={<Loading />}>
                    <OrderPage />
                  </Suspense>
                </UserAccess>
              }
            />
          ))}
          <Route
            path={ORDER_PAGE.ORDER_FLEXIBLE}
            element={
              <UserAccess
                userPermission={
                  allowedOrderTypes?.includes(ORDER_TYPE.TRAILER_BAY) &&
                  CREATE_DEMAND
                }
              >
                <Suspense fallback={<Loading />}>
                  <FlexibleDemand />
                </Suspense>
              </UserAccess>
            }
          />
          <Route
            path={ORDER_PAGE.ORDER_TYPE}
            element={
              <UserAccess
                userPermission={
                  allowedOrderTypes?.includes(ORDER_TYPE.TRAILER_BAY) &&
                  allowedOrderTypes?.includes(ORDER_TYPE.INDUSTRY_OFFTAKE) &&
                  CREATE_DEMAND
                }
              >
                <Suspense fallback={<Loading />}>
                  <OrderTypePage />
                </Suspense>
              </UserAccess>
            }
          />
          <Route
            path={PRODUCTION_PLAN_PAGE}
            element={
              <UserAccess userPermission={VIEW_PRODUCTION_PLANS}>
                <Suspense fallback={<Loading />}>
                  <ProductionPlan />
                </Suspense>
              </UserAccess>
            }
          />
          <Route
            path={SETTINGS_PAGE}
            element={
              <UserAccess
                userPermission={VIEW_SYSTEM_PROPERTY || UPDATE_SYSTEM_PROPERTY}
              >
                <Suspense fallback={<Loading />}>
                  <Settings />
                </Suspense>
              </UserAccess>
            }
          />
          <Route
            path={ORDER_PAGE.ORDER_HISTORY}
            element={
              <UserAccess userPermission={VIEW_DEMANDS}>
                <Suspense fallback={<Loading />}>
                  <OrderHistory />
                </Suspense>
              </UserAccess>
            }
          />
          <Route
            path={STATIC_COST_PAGE}
            element={
              <UserAccess userPermission={ADD_STATIC_COSTS}>
                <Suspense fallback={<Loading />}>
                  <StaticCost />
                </Suspense>
              </UserAccess>
            }
          />
          <Route
            path={USERS_PAGE}
            element={
              <UserAccess userPermission={VIEW_USER}>
                <Suspense fallback={<Loading />}>
                  <UserList />
                </Suspense>
              </UserAccess>
            }
          />
          <Route
            path={COMPANY}
            element={
              <UserAccess
                userPermission={
                  ONBOARD_COMPANY || DELETE_COMPANY || UPDATE_COMPANY_DETAILS
                }
              >
                <Suspense fallback={<Loading />}>
                  <CompaniesList />
                </Suspense>
              </UserAccess>
            }
          />
          <Route
            path={INVOICE_PAGE}
            element={
              <UserAccess userPermission={VIEW_ALL_INVOICES}>
                <Suspense fallback={<Loading />}>
                  <InvoiceList />
                </Suspense>
              </UserAccess>
            }
          />
          <Route
            path={ERROR_PAGE}
            element={
              <Suspense fallback={<Loading />}>
                <ErrorPage />
              </Suspense>
            }
          />
          <Route
            path={CONFIRMATION_PAGE}
            element={
              <Suspense fallback={<Loading />}>
                <Confirmation />
              </Suspense>
            }
          />
          {[
            ORDER_PAGE.ORDER_DETAILS,
            ORDER_PAGE.ORDER_HISTORY_ORDER_DETAILS,
            ORDER_PAGE.ORDER_TYPE_ORDER_DETAILS
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <UserAccess
                  userPermission={
                    allowedOrderTypes?.includes("INDUSTRY_OFFTAKE") &&
                    CREATE_DEMAND
                  }
                >
                  <Suspense fallback={<Loading />}>
                    <OrderDetails />
                  </Suspense>
                </UserAccess>
              }
            />
          ))}
          <Route
            path={PROFILE_PAGE}
            element={
              <Suspense fallback={<Loading />}>
                <Profile />
              </Suspense>
            }
          />
          {[
            ORDER_PAGE.ORDER_TYPE_FLEXIBLE,
            ORDER_PAGE.ORDER_HISTORY_FLEXIBLE
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <UserAccess
                  userPermission={
                    allowedOrderTypes?.includes("TRAILER_BAY") && CREATE_DEMAND
                  }
                >
                  <Suspense fallback={<Loading />}>
                    <FlexibleDemand />
                  </Suspense>
                </UserAccess>
              }
            />
          ))}
          {[
            ORDER_PAGE.ORDER_TYPE_FLEXIBLE_SUMMARY,
            ORDER_PAGE.ORDER_HISTORY_FLEXIBLE_SUMMARY
          ].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <UserAccess
                  userPermission={
                    allowedOrderTypes?.includes("TRAILER_BAY") && CREATE_DEMAND
                  }
                >
                  <Suspense fallback={<Loading />}>
                    <FlexibleDemandSummary />
                  </Suspense>
                </UserAccess>
              }
            />
          ))}
        </Route>
      </Routes>
  );
};

export default App;
