import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useContext
} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import styles from "./flexibledemand.module.css";
import crossIcon from "../../assets/Icons/close.svg";
import InfoIcon from "@mui/icons-material/Info";
import Datepicker from "../../components/date-picker/Datepicker";
import Timepicker from "../../components/time-picker/Timepicker";
import SelectInputWithObject from "../../components/select-input/SelectInputWithObject";
import {
  COMPANY_ID,
  IS_FLEXIBLE_DEMAND,
  START_DATE,
  START_HOUR,
  END_DATE,
  END_HOUR,
  TIMEZONE_CONSTANT,
  TOTAL_AMOUNT_LIMIT,
  HEADER_EDIT_FLEXIBLE_DEMAND,
  HEADER_ADD_FLEXIBLE_DEMAND,
  TOTAL_AMOUNT,
  CAPACITY,
  HINT_MESSAGE,
  ID,
  START_HOUR_PLACEHOLDER,
  DEFAULT_DATE_FORMAT,
  END_HOUR_PLACEHOLDER,
  END_DATE_PLACEHOLDER,
  COMPANY_ID_PLACEHOLDER,
  START_DATE_PLACEHOLDER,
  INFO_MESSAGE_FLEXIBLE_DEMAND,
  ID_PLACEHOLDER
} from "../Order/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getFlexibleEditOrderDetails,
  removeFirstItemFromFlexibleEditDemand,
  setFlexibleDemand,
  clearEditFlexibleDemand,
  setFlexibleDemandFlag,
  updateOrderFromFlexibleDemand,
  clearFlexibleDemand
} from "../../store/actions";
import {
  checkInput,
  isNotEmptyArray,
  isObjectEmpty,
  isObjectValuesEmpty,
  objectKeyContainsValues,
  omit,
  getOptionsWithIds,
  getOrderPath,
} from "../../utils/utils";
import { ORDER_PAGE, ORDER_TYPE } from "../../utils/RoutesConstants";
import {
  getMinimumStartDate,
  getMaxDays,
  convertDateFromUTCToCET,
  getMaxOneYear,
  getHourDifference,
  isCurrentTimezoneIsCET
} from "../../utils/date-utils";
import { orderSchema } from "../../validations";
import InputGroup from "../../components/input-group/InputGroup";
import { confirmContext, LinkWithConfirm } from "../../context/confirmContext";
import HeaderTitle from "../page-Header/HeaderTitle";

const FlexibleDemand = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setIsNeedToConfirm } = useContext(confirmContext);
  const {
    flexibleDemand: flexibleDemandArray,
    orderDetails,
    editOrderId: orderId,
    userDetails,
    editFlexibleDemand
  } = useSelector((state) => state.orderReducer);
  const {
    startDate: editOrderStartDate,
    startHour: editOrderStartTime,
    endDate: editOrderEndDate,
    endHour: editOrderEndTime,
    totalAmount: editOrderTotalCapacity,
    id: editOrderTrailerId,
    companyId: editOrderComapnyId
  } = orderDetails;
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    clearErrors
  } = useForm({
    resolver: yupResolver(orderSchema),
    defaultValues: {
      startDate: editOrderStartDate,
      endDate: editOrderEndDate,
      startHour: editOrderStartTime,
      endHour: editOrderEndTime,
      id: editOrderTrailerId ?? "",
      totalAmount: editOrderTotalCapacity,
      companyId: editOrderComapnyId
    },
    shouldFocusError: false
  });

  const allFieldValues = watch();
  const companiesTrailerBay = userDetails?.companies?.filter((itm) =>
    itm?.allowedOrderTypes.includes(ORDER_TYPE.TRAILER_BAY)
  );
  const checkButtonIsDisabled = () =>
    isObjectValuesEmpty(omit(allFieldValues, [COMPANY_ID])) ||
    !isObjectEmpty(errors);
  const isBtnDisabled =
    checkButtonIsDisabled() ||
    (companiesTrailerBay?.length > 1 &&
      orderId === "" &&
      !allFieldValues?.companyId);

  const canNavigateWithoutValidate =
    !objectKeyContainsValues(omit(allFieldValues, [IS_FLEXIBLE_DEMAND])) &&
    isNotEmptyArray(flexibleDemandArray);

  const { id, startDate, endDate, startHour, endHour, totalAmount } =
    allFieldValues;
  const getStartDate = () => getMinimumStartDate();
  const getEndDate = () => getMaxOneYear();

  const startDateInputRef = useRef(null);
  const startDateInput = register(START_DATE, {
    onChange: (e) => {
      if (endDate || endHour || startHour) {
        clearErrors([END_DATE, END_HOUR, START_HOUR]);
      }
    }
  });
  const handleCompanyChange = useCallback(
    (value) => {
      setValue(COMPANY_ID, value);
    },
    [setValue]
  );

  const handleOrderDetails = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (value === "" || checkInput(value, name)) {
      setValue(name, value);
    }
  };

  const endDateInputRef = useRef(null);
  const endDateInput = register(END_DATE, {
    onChange: (e) => {
      if (startDate || endHour || startHour) {
        clearErrors([START_DATE, END_HOUR, START_HOUR]);
      }
    }
  });

  const onSubmitHandler = (data) => {
    const { flexibleDemand, ...restData } = data;
    if (editFlexibleDemand) {
      dispatch(updateOrderFromFlexibleDemand(restData));
    } else {
      dispatch(setFlexibleDemand(restData));
    }
    dispatch(setFlexibleDemandFlag(false));
    isNotEmptyArray(flexibleDemandArray) &&
      orderId &&
      dispatch(removeFirstItemFromFlexibleEditDemand());
    orderId &&
      dispatch(
        getFlexibleEditOrderDetails({
          startDate,
          startHour,
          endDate,
          endHour,
          totalAmount,
          id,
          orderId,
          shouldClearOrder: true
        })
      );
    navigate(ORDER_PAGE.ORDER_TYPE_FLEXIBLE_SUMMARY);
  };
  const [isCrossIconClicked, setIsCrossIconClicked] = useState(true);
  const checkIsFormDirty = useCallback(() => {
    if (
      startDate ||
      startHour ||
      endDate ||
      endHour ||
      orderDetails.id ||
      orderDetails.totalAmount
    ) {
      if (!isBtnDisabled && isCrossIconClicked) {
        return true;
      } else if (!isBtnDisabled && !isCrossIconClicked) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }, [
    endDate,
    endHour,
    startDate,
    startHour,
    isBtnDisabled,
    isCrossIconClicked,
    orderDetails.totalAmount,
    orderDetails.id
  ]);
  useEffect(() => {
    if (checkIsFormDirty()) {
      setIsNeedToConfirm(true);
    }
    return () => {
      setIsNeedToConfirm(false);
    };
  }, [checkIsFormDirty, setIsNeedToConfirm]);

  useEffect(() => {
    if (companiesTrailerBay && companiesTrailerBay?.length === 1) {
      setValue(COMPANY_ID, companiesTrailerBay[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue]);

  return (
    <div className={styles.flexibleDemand}>
      <div className={styles.flexibleDemand_Container}>
        <div className={`${styles.headerContainer} ${styles.flexibleDemand_header}`}>
        <HeaderTitle title={orderId ? HEADER_EDIT_FLEXIBLE_DEMAND : HEADER_ADD_FLEXIBLE_DEMAND} titleWithoutButton={true}/>
        <div className={styles.crossIcon}>
          <LinkWithConfirm href={orderId ? ORDER_PAGE.ORDER_HISTORY : getOrderPath(userDetails)} action={clearEditFlexibleDemand} clearFlexibleDemand={clearFlexibleDemand}>
            <img src={crossIcon} alt="crossIcon"data-testid="toBack"/>
          </LinkWithConfirm>
        </div>
        </div>
        {!isCurrentTimezoneIsCET() && (
          <div className={styles.infoMessage}>
            <div>
              <InfoIcon className={styles.infoIcon} />
            </div>{" "}
            <p style={{ fontStyle: "italic" }}>{TIMEZONE_CONSTANT}</p>
          </div>
        )}
        <div className={styles.flexibleDemand_context}>
          <p>
            {INFO_MESSAGE_FLEXIBLE_DEMAND}
          </p>
        </div>
        <div className={styles.formContainer}>
          <form
            autoComplete="off"
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
          >
            <input
              type="hidden"
              value={true}
              name={IS_FLEXIBLE_DEMAND}
              {...register(IS_FLEXIBLE_DEMAND)}
              readOnly
            />
            <div className={styles.flexibleDemand_form}>
              <h3 className={styles.formHeading}>Facility Information</h3>
              <InputGroup
                value={id}
                inputProps={{
                  id: ID,
                  type: "text",
                  ...register(ID),
                  maxLength: TOTAL_AMOUNT_LIMIT,
                  placeholder: ID_PLACEHOLDER,
                  onChange: (e) => {
                    e.preventDefault();
                    clearErrors(ID);
                    handleOrderDetails(e);
                  }
                }}
                rightLabel={null}
                showWarningDemand={false}
                errorMsg={errors?.id?.message}
                extraWrapperClass={styles.inputGroup___wrapper}
              />

              <InputGroup
                value={totalAmount}
                inputProps={{
                  id: TOTAL_AMOUNT,
                  type: "text",
                  ...register(TOTAL_AMOUNT),
                  maxLength: TOTAL_AMOUNT_LIMIT,
                  placeholder: CAPACITY,
                  onChange: (e) => {
                    e.preventDefault();
                    clearErrors(TOTAL_AMOUNT);
                    handleOrderDetails(e);
                  }
                }}
                rightLabel="kg"
                showWarningDemand={false}
                errorMsg={errors?.totalAmount?.message}
                inputHint={HINT_MESSAGE}
                extraWrapperClass={styles.inputGroup___wrapper}
              />

              <h3 className={styles.scheduleHeading}>Schedule</h3>
              <div className={styles.schedule}>
                <Datepicker
                  name={START_DATE}
                  value={startDate}
                  inputProps={{
                    id: START_DATE,
                    min: getStartDate(),
                    max: getEndDate(),
                    ...startDateInput,
                    ref: (e) => {
                      startDateInput.ref(e);
                      startDateInputRef.current = e;
                    },
                    placeholder:START_DATE_PLACEHOLDER
                  }}
                  inputRef={startDateInputRef}
                  errorMsg={errors?.startDate?.message}
                  extraWrapperClass={styles.flexibleDemand_DatePicker}
                />

                <Timepicker
                  name={START_HOUR}
                  inputProps={{
                    id: START_HOUR,
                    ...register(START_HOUR, {
                      onChange: (e) => {
                        if (startDate || endDate || endHour) {
                          clearErrors([START_DATE, END_DATE, END_HOUR]);
                        }
                      }
                    }),
                    placeholder: START_HOUR_PLACEHOLDER
                  }}
                  setValue={setValue}
                  hour={startHour}
                  clearErrors={clearErrors}
                  errorMsg={errors?.startHour?.message}
                  extraWrapperClass={styles.flexibleDemand_DatePicker}
                />
              </div>
              <div className={styles.schedule}>
                <Datepicker
                  name={END_DATE}
                  value={endDate}
                  inputProps={{
                    id: END_DATE,
                    min: startDate || getStartDate(),
                    max:
                    getHourDifference(
                        allFieldValues?.startDate,
                        getMaxOneYear()
                      ) > 168
                        ? convertDateFromUTCToCET(
                            getMaxDays(allFieldValues?.startDate, 7),
                            DEFAULT_DATE_FORMAT
                          )
                        : convertDateFromUTCToCET(
                            getMaxDays(
                              allFieldValues?.startDate,
                              getHourDifference(
                                allFieldValues?.startDate,
                                getMaxOneYear()
                              ) / 24
                            ),
                           DEFAULT_DATE_FORMAT
                          ),
                    ...endDateInput,
                    ref: (e) => {
                      endDateInput.ref(e);
                      endDateInputRef.current = e;
                    },
                    placeholder: END_DATE_PLACEHOLDER
                  }}
                  inputRef={endDateInputRef}
                  errorMsg={errors?.endDate?.message}
                  extraWrapperClass={styles.flexibleDemand_DatePicker}
                />

                <Timepicker
                  name={END_HOUR}
                  inputProps={{
                    id: END_HOUR,
                    ...register(END_HOUR, {
                      onChange: (e) => {
                        if (startDate || endDate || startHour) {
                          clearErrors([START_DATE, END_DATE, START_HOUR]);
                        }
                      }
                    }),
                    placeholder: END_HOUR_PLACEHOLDER
                  }}
                  setValue={setValue}
                  hour={endHour}
                  clearErrors={clearErrors}
                  errorMsg={errors?.endHour?.message}
                  extraWrapperClass={styles.flexibleDemand_TimePicker}
                />
              </div>
              {companiesTrailerBay?.length > 1 && orderId === "" && (
                <SelectInputWithObject
                  value={allFieldValues?.companyId || ''}
                  options={getOptionsWithIds(companiesTrailerBay)}
                  rawOptions={companiesTrailerBay}
                  inputProps={{
                    id: COMPANY_ID,
                    ...register(COMPANY_ID),
                    placeholder: COMPANY_ID_PLACEHOLDER,
                    dataTestId: "selectCompany"
                  }}
                  onChangeCallBackFn={(e) => handleCompanyChange(e)}
                />
              )}
            </div>
            <div className={styles.flexibleDemand_btn}>
              <LinkWithConfirm
                href={orderId ? ORDER_PAGE.ORDER_HISTORY : "/"}
                action={clearEditFlexibleDemand}
                clearFlexibleDemand={clearFlexibleDemand}
              >
                <button
                  className={`${styles.btn} ${styles.backBtn}`}
                  data-testid="backBtn"
                >
                  Back
                </button>
              </LinkWithConfirm>
              {canNavigateWithoutValidate ? (
                <button
                  className={`${styles.btn} ${styles.orderBtn}`}
                  data-testid="moveNext"
                  type="button"
                  onClick={() =>
                    navigate(ORDER_PAGE.ORDER_TYPE_FLEXIBLE_SUMMARY)
                  }
                >
                  Next
                </button>
              ) : (
                <button
                  disabled={isBtnDisabled}
                  className={`${
                    (isBtnDisabled && isNotEmptyArray(flexibleDemandArray)) ||
                    isBtnDisabled
                      ? `${styles.orderBtn__disabled} ${styles.btn} ${styles.orderBtn}`
                      : `${styles.btn} ${styles.orderBtn}`
                  }`}
                  data-testid="moveNext"
                  type="submit"
                  onMouseEnter={() => setIsCrossIconClicked(false)}
                  onMouseLeave={() => setIsCrossIconClicked(true)}
                >
                  Next
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FlexibleDemand;
