export const GET_ORDER_HISTORY = "getOrderHistory";
export const EDIT_ORDER = "editOrder";
export const PLACE_ORDER = "placeOrder";
export const ORDER_DETAILS = "orderDetails";
export const CLEAR_ORDER_DETAILS = "clearOrderDetails";
export const CLEAR_ROW_ORDER_DETAILS = "CLEAR_ROW_ORDER_DETAILS";
export const CLEAR_INVOICE_DETAILS = "CLEAR_INVOICE_DETAILS";
export const GET_USER_DETAILS = "getUserDetails";
export const SET_SETTING_DETAILS = "setSettingDetails";
export const SET_NETWORK_ERROR_MESSAGE = "setNetworkErrorMessage";
export const SET_FLEXIBLE_DEMAND = "setFlexibleDemand";
export const CLEAR_FLEXIBLE_DEMAND = "clearFlexibleDemand";
export const SET_FLEXIBLE_DEMAND_ERRORS = "setFlexibleDemandErrors";
export const GET_EDIT_ORDER_DETAILS = "getEditOrderDetails";
export const GET_STATIC_COST_DETAILS = "getStaticCostDetails";
export const GET_USER_LIST_DETAILS = "getUserListDetails";
export const MAINTENANCE_DETAILS = "maintenanceDetails";
export const GET_COMPANIES_LIST_DETAILS = "GET_COMPANIES_LIST_DETAILS";
export const CLEAR_MAINTENANCE_DETAILS = "clearMaintenanceDetails";
export const SET_LOADING = "SET_LOADING";
export const SET_LOADING_FALSE = "SET_LOADING_FALSE";
export const CLEAR_USER_LIST_DETAILS ="CLEAR_USER_LIST_DETAILS";
export const CLEAR_COMPANY_LIST_DETAILS = "CLEAR_COMPANY_LIST_DETAILS";
export const CLEAR_STATIC_COST_DETAILS = "clearStaticCostDetails";
export const FETCH_DATA_REQUEST = "FETCH_DATA_REQUEST";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_USER_DATA_SUCCESS = "FETCH_USER_DATA_SUCCESS";
export const FETCH_INVOICE_DATA_SUCCESS = "FETCH_INVOICE_DATA_SUCCESS";
export const FETCH_ROLES_DATA_SUCCESS = "FETCH_ROLES_DATA_SUCCESS";
export const FETCH_COMPANIES_DATA_SUCCESS = "FETCH_COMPANIES_DATA_SUCCESS";
export const FETCH_DATA_ERROR = "FETCH_DATA_ERROR";
export const FETCH_INVOICE_DETAILS_SUCCESS = "FETCH_INVOICE_DETAILS_SUCCESS";
export const FETCH_ORDER_DETAILS_SUCCESS = "FETCH_ORDER_DETAILS_SUCCESS";
export const FETCH_TYPES_SUCCESS = "FETCH_TYPES_SUCCESS";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const GET_FLEXIBLE_DEMAND_EDIT_ORDER_DETAILS =
  "getFlexibleDemandEditOrderDetails";
export const CLEAR_EDIT_FLEXIBLE_DEMAND = "clearEditFlexibleDemand";
export const REMOVE_FIRST_ITEM_FLEXIBLE_EDIT_DEMAND =
  "RemoveFirstItemFlexibleEditDemand";
export const IS_INPUT_EMPTY = "isInputEmpty";
export const CLEAR_EMPTY_INPUT_ERRORS = "clearEmptyInputErrors";
export const NO_USER_ROLE_MESSAGE = `User Role(s) Not Specified`;
export const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z]+$/;

export const DEFAULT_COMPANY_NAME = "Everfuel";

export const CREATE_DEMAND = 'CREATE_DEMAND';
export const UPDATE_DEMAND = 'UPDATE_DEMAND';
export const VIEW_DEMANDS = 'VIEW_DEMANDS';
export const PLACE_ORDERS = 'PLACE_ORDER';
export const APPROVE_DEMAND = 'APPROVE_DEMAND';
export const REJECT_DEMAND = 'REJECT_DEMAND';
export const VIEW_PRODUCTION_PLANS = 'VIEW_PRODUCTION_PLANS';
export const ADD_USER = 'ADD_USER';
export const VIEW_USER = 'VIEW_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const UPDATE_COMPANY_DETAILS = 'UPDATE_COMPANY_DETAILS';
export const ONBOARD_COMPANY = 'ONBOARD_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const ADD_USER_TO_HYOP = 'ADD_USER_TO_HYOP';
export const UPDATE_USER_IN_HYOP = 'UPDATE_USER_IN_HYOP';
export const DELETE_USER_FROM_HYOP = 'DELETE_USER_FROM_HYOP';
export const ADD_ROLE_TO_USER = 'ADD_ROLE_TO_USER';
export const UPDATE_ROLE_FOR_USER = 'UPDATE_ROLE_FOR_USER';
export const DELETE_ROLE_FROM_USER = 'DELETE_ROLE_FROM_USER';
export const VIEW_CURRENT_PRODUCTION_PLAN = 'VIEW_CURRENT_PRODUCTION_PLAN';
export const VIEW_SYSTEM_PROPERTY = 'VIEW_SYSTEM_PROPERTY';
export const UPDATE_SYSTEM_PROPERTY = 'UPDATE_SYSTEM_PROPERTY';
export const GENERATE_NEW_PRODUCTION = 'GENERATE_NEW_PRODUCTION';
export const READ_ONLY_ACCESS_TO_HYOP = 'READ_ONLY_ACCESS_TO_HYOP';
export const VIEW_ALL_ORDERS_AND_DETAILS = 'VIEW_ALL_ORDERS_AND_DETAILS';
export const VIEW_ALL_INVOICES = 'VIEW_ALL_INVOICES';
export const ADD_STATIC_COSTS = 'ADD_STATIC_COSTS';
export const UPDATE_STATIC_COSTS = 'UPDATE_STATIC_COSTS';

export  const ACTIONS_NAME = {
  STATIC_COST:"staticCost",
  USER:"user",
  INVOICES:"invoices",
  ROLES:"roles",
  TYPES:"types",
  COMPANIES:"companies",
  INVOICE_DETAILS: "invoiceDetails",
  USER_DETAILS: "selectedUserDetails",
  MAINTENANCE:"maintenance"

}

export const PERMISSION_ERROR_MESSAGE = `You don't have permission to access this page`;
export const PERMISSION_ERROR_HEADER = `An error has occurred`;
export const BUTTON_NAME = 'Home';
export const NO_ROW_OVERLAY_MESSAGE = 'No Static Costs found.';
export const NO_ROW_INVOICE_OVERLAY_MESSAGE = 'No Invoices found.';
export const NO_ROW_OVERLAY_PROD_PLAN_MESSAGE = 'No production plan found';
export const NO_ROW_OVERLAY_ORDER_HISTORY_MESSAGE ='No OrderHistory found.';
export const USER_NO_ROW_OVERLAY_MESSAGE = 'No Users found.';
export const COMPANIES_NO_ROW_OVERLAY_MESSAGE = "No Companies Found.";
export const NO_SUPPLY_LINE_MESSAGE = "No Supply Lines found.";


export const DELETE_ORDER_FLEXIBLE_DEMAND = "DELETE_ORDER_FLEXIBLE_DEMAND";
export const UPDATE_ORDER_FLEXIBLE_DEMAND = "UPDATE_ORDER_FLEXIBLE_DEMAND";
export const SET_FLEXIBLEDEMAND_FLAG = "SET_FLEXIBLEDEMAND_FLAG";
export const CURRENT_INDEX = "CURRENT_INDEX";
