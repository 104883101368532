export const SIGN_IN_BUTTON = "Sign In to Order";
export const TRANS_KEYS = {
    translation :"translation",
    INVOICES:"Invoices",
    INVOICE_DETAILS:"Invoices.Invoice_Details",
    INVOICE_DETAILS_TABLE:"Invoices.Invoice_Details.Invoice_Detail_Table",
    USERLIST : "Users",
    USER_DATA :"UserDetails",
    COMPANIES_LIST:"Companies",
    MAINTENANCE:'maintenance'
}
export const SIDER = {
    greet:"Welcome",
    email :"operations@everfuel.com",
    phone:"+45 45454545"
}

export const REGEX = {
    NUMBER:  /^\d+$/,
    ALPHABET_WITH_SPECIAL_CHARACTER: /^[a-zA-Z0-9,_ØÆÅøæå -]{0,250}$/
  }

export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const UNITS = {
    percentage: "%"
}
