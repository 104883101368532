import React, { useCallback, useEffect, useState, useContext } from "react";
import style from "./header.module.css";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import CustomAppBar from "../customAppBar/CustomAppBar.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { API } from "../../utils/API";
import MenuIcon from "@mui/icons-material/Menu";
import {
  clearOrderDetails,
  getuserDetails,
  setNetworkErrorMessage,
  setSettingDetails
} from "../../store/actions";
import { LinkWithConfirm } from "../../context/confirmContext";
import { styled } from "@mui/material/styles";
import { Toolbar, IconButton } from "@mui/material";
import { UserContext } from "../../pages/AuthorizedPage/UserProvider";
import { errorHandler } from "../../utils/utils";
import { ORDER_PAGE, PROFILE_PAGE } from "../../utils/RoutesConstants";
import PropTypes from 'prop-types';
const Header = ({ open, handleDrawerOpen }) => {
  const drawerWidth = 400;
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { signIn, signOut } = useContext(UserContext);
  const firstName = useSelector(
    (state) => state?.orderReducer?.userDetails?.firstName
  );
  const lastName = useSelector(
    (state) => state?.orderReducer?.userDetails?.lastName
  );
  function stringAvatar(name) {
    return {
      children: `${name.split(" ")[0][0]?.toUpperCase()}${name
        .split(" ")[1][0]
        ?.toUpperCase()}`
    };
  }
  const getUserDetails = useCallback(async () => {
    try {
      const data = await API.get(API.PROFILE_BASE_URL);
      if (data) {
        dispatch(getuserDetails(data.data));
      }
    } catch (error) {
      errorHandler(error, navigate, dispatch, signIn, signOut, "Profile");
    }
  }, [dispatch, navigate, signIn, signOut]);

  const getSettings = useCallback(async () => {
    try {
      const res = await API.get(API.SETTING_BASE_URL);
      if (res) {
        dispatch(setSettingDetails(res.data));
      }
    } catch (error) {
      dispatch(
        setNetworkErrorMessage({
          errorMessage: error.message
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  useEffect(() => {
    getUserDetails();
  }, [getUserDetails]);

  function capitalizeFirstLetter(string) {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  }
  const toCallClearOrders = () => {
    return (
      location.pathname === ORDER_PAGE.ORDER ||
      location.pathname === ORDER_PAGE.ORDER_TYPE_FLEXIBLE ||
      location.pathname === ORDER_PAGE.ORDER_HISTORY_FLEXIBLE ||
      location.pathname === ORDER_PAGE.ORDER_TYPE_INDUSTRY||
      location.pathname === ORDER_PAGE.ORDER_HISTORY_INDUSTRY
    );
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 0),
    ...theme.mixins.toolbar
  }));

  const [showBackground, setShowBackground] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 30) {
        setShowBackground(true);
      } else {
        setShowBackground(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
      <DrawerHeader>
        <CustomAppBar
          color="transparent"
          sx={{
            width: "100%",
            ml: `${drawerWidth}px`,
            display: !showBackground ? "block" : "none"
          }}
          className={style.head}
          position="fixed"
          open={open}
        >
          <Toolbar className={style.header}>
            <div style={{ width: "100%" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                data-testid="btnBurger"
                onClick={handleDrawerOpen}
                edge="start"
                className={style.placeOrder__nextBtn}
                sx={{
                  height: 40,
                  marginRight: 5,
                  ...(open && { display: "none" })
                }}
              >
                <MenuIcon />
              </IconButton>
            </div>
            <Typography variant="h6" component="div">
              <LinkWithConfirm
                href={PROFILE_PAGE}
                action={toCallClearOrders && clearOrderDetails}
              >
                <div className={style.header__profile}>
                  <Avatar
                    {...stringAvatar(`${firstName} ${lastName}`)}
                    sx={{
                      height: "40px",
                      width: "40px",
                      color: "#efc415",
                      background: "#2d4254"
                    }}
                  />
                  <span className={style.profile__name}>
                    {capitalizeFirstLetter(firstName)}{" "}
                    {capitalizeFirstLetter(lastName)}
                  </span>
                </div>
              </LinkWithConfirm>
            </Typography>
          </Toolbar>
        </CustomAppBar>
      </DrawerHeader>
  );
};
Header.propTypes = {
  open : PropTypes.bool,
  handleDrawerOpen:PropTypes.func,
};

export default Header;
