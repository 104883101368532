import React, { useState, useEffect } from "react";
import style from "./LoginLanding.module.css";
import App from "./App";
import logo from "./assets/Icons/logo.svg";
import { useIsAuthenticated } from "@azure/msal-react";
import Graphic from "./assets/Icons/Graphic.svg";
import Home from "./pages/Home/Home";
import { useTranslation } from "react-i18next";
import { SIGN_IN_BUTTON } from "./utils/Constants";

const imagePaths = [
  {
    image: `${process.env.PUBLIC_URL} /Images/Sun.jpg`,
    color: "var(--darkYellow)"
  },
  {
    image: `${process.env.PUBLIC_URL} /Images/Water.jpg`,
    color: "var(--skyBlue)"
  },
  {
    image: `${process.env.PUBLIC_URL} /Images/Wind.jpg`,
    color: "var(--darkBlue)"
  }
];

const LoginLanding = () => {
  const { t } = useTranslation();
  const [flag, setFlag] = useState(false);
  const [currentHour, setCurrentHour] = useState(new Date().getHours());
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentHour(new Date().getHours());
    }, 3600000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const imageIndex = (currentHour + currentImageIndex) % imagePaths.length;
    setCurrentImageIndex(imageIndex);
  }, [currentHour]);

  const handleClick = () => {
    setFlag(!flag);
  };

  return (
    <div
      style={{
        backgroundImage: `url(${imagePaths[currentImageIndex].image})`,
        backgroundColor: `${imagePaths[currentImageIndex].color}`
      }}
      className={style.backgroundImage}
    >
      {!isAuthenticated && (
        <>
          <span className={style.home_container}>
            <span className={style.logo} data-testid="logo">
              <img className={style.img_logo} src={logo} alt="logo" />
            </span>
            <span className={style.btn}>
              <button className={style.firstBtn} onClick={handleClick}>
                {t(SIGN_IN_BUTTON)}
              </button>
            </span>
          </span>
          <div className={style.img_container}>
            <img className={style.img} src={Graphic} alt="settings" />
            <Home />
          </div>
        </>
      )}

      {(flag || isAuthenticated) && <App />}
    </div>
  );
};

export default LoginLanding;
