import React from "react";
import styles from "./HeaderTitle.module.css";
import ArrowLeft from "../../assets/Icons/ArrowLeft.svg";
import ArrowRight from "../../assets/Icons/ArrowRight.svg";
import { Chip, Typography } from "@mui/material";

const HeaderTitle = (props) => {
  const {
    data,
    title,
    allowForward,
    allowBackward,
    handleClick,
    handleBackClick,
    withButton,
    buttonName,
    graphButon,
    withIndicator,
    indicatorColor,
    titleWithoutButton,
    isRegularFontSize,
    id
  } = props;

  const renderWithTitleButton = () => (
    <div className={styles.staticCost__title}>
      <h1>{title}</h1>
      <div className={styles.addStaticCostButton}>
        <button
          className={styles.placeOrder__nextBtn}
          onClick={handleClick}
          data-testid={id}
        >
          {buttonName}
        </button>
      </div>
    </div>
  );

  const renderTitleWithHiddenButton = () => (
    <div className={styles.staticCost__title}>
      <h1>{title}</h1>
    </div>
  );

  const renderWithTitleGraphButton = () => (
    <div className={styles.header__title}>
      <h1 style={{ width: withIndicator && "auto" }}>{title}</h1>
      {withIndicator && (
        <div className={styles.indicator}>
          <Chip
            data-testid="chipIndicator"
            style={{
              height: 25,
              background:
                indicatorColor === "primary" && "transparent" ||
                indicatorColor === "warning" && "var(--yellowColor)"
            }}
            color={indicatorColor}
            size="medium"
          />
        </div>
      )}
      {data?.length !== 0 && (
        <div
          className={styles.btnContainer}
          style={{ width: withIndicator && "70%" }}
        >
          <button
            className={`${styles.btn} ${
              !allowBackward
                ? `${styles.btn_Disabled} ${styles.btnBackward}`
                : styles.btnBackward
            }`}
            data-testid="BtnBackward"
            disabled={!allowBackward}
            onClick={handleBackClick}
          >
            <img style={{ height: 12 }} src={ArrowLeft} alt="arrow" />
          </button>
          <button
            className={`${styles.btn} ${
              !allowForward
                ? `${styles.btn_Disabled} ${styles.btnForward}`
                : styles.btnForward
            }`}
            data-testid="BtnForward"
            disabled={!allowForward}
            onClick={handleClick}
          >
            <img style={{ height: 12 }} src={ArrowRight} alt="arrow" />
          </button>
        </div>
      )}
    </div>
  );

  const renderTitleWithoutButton = () => (
    <div
      className={
        isRegularFontSize
          ? `${styles.flexibleDemand_header} ${styles.bigFontSize}`
          : styles.flexibleDemand_header
      }
    >
      <h1>{title}</h1>
    </div>
  );

  if (withButton) {
    return renderWithTitleButton();
  } else if(withButton === false){
    return renderTitleWithHiddenButton();
  }else if (graphButon) {
    return renderWithTitleGraphButton();
  } else if (titleWithoutButton) {
    return renderTitleWithoutButton();
  } else {
    return (
      <Typography
        sx={{
          height: "56px",
          fontFamily: "Source Sans Pro",
          fontSize: "45px",
          fontWeight: 900,
          lineHeight: "56px",
          width: "100%",
          letterSpacing: "0em",
          textAlign: "left",
          color: "var(--yellowColor)"
        }}
      >
        {title}
      </Typography>
    );
  }
};

export default HeaderTitle;
