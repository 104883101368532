export const ErrorMessage = {
  PROFILE_NOT_FOUND_ERROR_MESSAGE:
    'Your profile cannot be found. Please get in touch with "Mikkel Damgaard" to be onboarded to HyOPs.',
  ORDER_HISTORY_NOT_FOUND_MESSAGE: "No OrderHistory found.",
  SETTINGS_NOT_FOUND_MESSAGE: "No Settings Found",
  PRODUCTION_PLAN_NOT_FOUND_MESSAGE: "No production plan found",
  FLEXIBLE_DEMAND_SUMMARY_NOT_FOUND_MESSAGE: "No Flexible Demand Found.",
  ORDER_DETAILS_NOT_FOUND_MESSAGE: "No Order Details found.",
  EDIT_ORDER_NOT_FOUND_MESSAGE: "Order not found",
  STATIC_COST_NOT_FOUND_MESSAGE: "No Static Costs found.",
  ACCESS_DENIED: `You don't have permission to access this page`,
  LOGIN_REQUIRED: "Login Required",
  UNCAUGHT_EXCEPTION: "Something went wrong, Please try again later",
  DEFAULT: "Requested data not found",
  PAGE_NOT_FOUND: "404 - Page Not Found",
  USER_NOT_FOUND_MESSAGE: "User Not Found",
  COMPANIES_NOT_FOUND_MESSAGE: "Company Not Found.",
  SETTING_ERROR_MESSAGE: "Value must be shorter then 6 characters",
  MAINTENANCE_NOT_FOUND_MESSAGE: "Maintenance Not Found",
};

export const ErrorCodes = {
  LoginRequired: "LoginRequired",
  NotFound: "NotFound",
  AccessDenied: "AccessDenied",
  UncaughtException: "UncaughtException",
  LogOutRequired: "LogOutRequired",
  BadRequest: "BadRequest"
};
export const ErrorStatusCode = {
  SUCCESS: 200
};
